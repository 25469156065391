import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './chat.module.css';
import { getChats } from '../services/api';
import { Message } from '../components/message';
import { Input } from '../components/input';
import sendIcon from '../images/send.svg';
import closeImage from '../images/close.svg';
import { timeDifference } from '../services/utils';

export const ChatPage = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getChatsData = async () => {
    setLoading(true);
    try {
      const chats = await getChats();
      setData(chats);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatsData();
  }, []);

  let { chatId } = useParams();

  const selectedChat = useMemo(
    () => {
      return data.find(chat => chat.id.toString() === chatId.toString());
    },
    [chatId, data]
  );

  const lastSeenText = useMemo(
    () =>
      (selectedChat &&
        'Был(а) в сети ' + timeDifference(Date.now(), selectedChat.lastSeen * 1000)) ||
      null,
    [selectedChat]
  );
  if (!loading && selectedChat) {
    return (
      <div className={styles.container}>
        <div className={styles.chatHeader}>
          <div className={styles.recipientInfo}>
            <p className={styles.recipient}>{selectedChat.recipientName}</p>
            <p className={styles.lastSeen}>{lastSeenText}</p>
          </div>
          <img
            src={closeImage}
            alt="close"
            onClick={() => history.replace({ pathname: '/list' })}
          />
        </div>
        <div className={styles.messagesWrapper}>
          {selectedChat.messages.map((m, index) => (
            <Message message={m} key={index} />
          ))}
        </div>
        <div className={styles.replyBar}>
          <Input placeholder="Сообщение" />
          <img src={sendIcon} alt="send" />
        </div>
      </div>
    );
  }
  return null;
};
