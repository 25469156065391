import React, { useCallback, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './chats-list.module.css';
import { Avatar } from '../avatar';
import { getTimeFromTimestamp } from '../../services/utils';
import { useAuth } from '../../services/auth';

const getLastMessage = messages => {
  return [...messages].pop();
};

const normalizeData = chats => {
  return chats.map(({ recipientName, id, messages }) => {
    const lastMessage = getLastMessage(messages);
    return {
      recipientName,
      id,
      timestamp: lastMessage.timestamp,
      lastMessage: lastMessage
    };
  });
};

export const ChatsList = ({ chats }) => {
  const history = useHistory();
  const normalizedData = React.useMemo(() => normalizeData(chats), [chats]);
  const {
    user: { id }
  } = useAuth();

  const lastMessageTextPrefix = useCallback(
    messageId => (id === messageId ? <span>Вы: </span> : null),
    [id]
  );

  const messageClassname = useMemo(
    () =>
      `${styles.lastMessage} ${history.location.pathname === '/list' ? '' : styles.messageLimited}`,
    [history.location.pathname]
  );

  return (
    <ul>
      {normalizedData.map(({ recipientName, id, timestamp, lastMessage }, index) => (
        <li key={index}>
          <NavLink
            to={{ pathname: `/list/${id}`, state: history.location.state }}
            className={styles.chat}
            activeClassName={styles.activeChat}
          >
            <Avatar name={recipientName} />
            <div className={styles.chatContent}>
              <div className={styles.info}>
                <span>{recipientName}</span>
                <span>{getTimeFromTimestamp(timestamp)}</span>
              </div>
              <div className={messageClassname}>
                {lastMessageTextPrefix(lastMessage.senderId)}
                <span>{lastMessage.text}</span>
              </div>
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
